    import bg from "./assets/images/client.jpg";
    import underline from "./assets/images/underline.png";
    
    
    function Client(){
    return(
<div>
<section class="client_section layout_padding-bottom" data-aos="zoom-in">
    <div class="container">
        <div class="heading_container heading_center">
            <h2>
                Testimonial
            </h2>
    <img src={underline} style={{width:"300px"}}></img>
        </div>
        <div id="carouselExample2Controls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="row">
                        <div class="col-md-11 col-lg-10 mx-auto">
                            <div class="box">
                                <div class="img-box">
                                   <img src={bg}/>
                                </div>
                                <div class="detail-box">
                                    <div class="name">
                                        <h6>
                                            Alan Emerson
                                        </h6>
                                    </div>
                                    <p>
                                        Enim consequatur odio assumenda voluptas voluptatibus esse nobis officia. Magnam, aspernatur nostrum explicabo, distinctio laudantium delectus deserunt quia quidem magni corporis earum inventore totam consectetur corrupti! Corrupti, nihil sunt? Natus.
                                    </p>
                                    <i class="fa fa-quote-left" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-11 col-lg-10 mx-auto">
                            <div class="box">
                                <div class="img-box">
                                    <img src={bg} alt="" />
                                </div>
                                <div class="detail-box">
                                    <div class="name">
                                        <h6>
                                            Alan Emerson
                                        </h6>
                                    </div>
                                    <p>
                                        Enim consequatur odio assumenda voluptas voluptatibus esse nobis officia. Magnam, aspernatur nostrum explicabo, distinctio laudantium delectus deserunt quia quidem magni corporis earum inventore totam consectetur corrupti! Corrupti, nihil sunt? Natus.
                                    </p>
                                    <i class="fa fa-quote-left" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-11 col-lg-10 mx-auto">
                            <div class="box">
                                <div class="img-box">
                                    <img src={bg} alt="" />
                                </div>
                                <div class="detail-box">
                                    <div class="name">
                                        <h6>
                                            Alan Emerson
                                        </h6>
                                    </div>
                                    <p>
                                        Enim consequatur odio assumenda voluptas voluptatibus esse nobis officia. Magnam, aspernatur nostrum explicabo, distinctio laudantium delectus deserunt quia quidem magni corporis earum inventore totam consectetur corrupti! Corrupti, nihil sunt? Natus.
                                    </p>
                                    <i class="fa fa-quote-left" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel_btn-container">
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample2Controls" data-bs-slide="prev">
                    <i class="bi bi-arrow-left" aria-hidden="true"></i>
                    <span class="sr-only">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExample2Controls" data-bs-slide="next">
                    <i class="bi bi-arrow-right" aria-hidden="true"></i>
                    <span class="sr-only">Next</span>
                </button>
            </div>
        </div>
    </div>
</section>

</div>
    );
};
export default Client;