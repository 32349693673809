import React, { useEffect } from 'react'
import health from './assets/images/health-img.png'
import { Link } from 'react-router-dom';

const HealthPackage = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <div>
            <div className="container">
                <div className="row  ">
                    <div className="col-lg-6 col-md-12 health-left px-lg-5 px-sm-0 " data-aos="fade-up-left">
                        <h1 style={{ color: "grey" }}><span style={{ color: '#2e2f62', fontWeight: 'bold', fontSize: '50px' }}>Sahaya Annai Hospital</span> Preventive Health Check-Up Packages</h1>
                        <p>At Sahaya Annai Hospital, we believe in the power of prevention and the promise of survival. Our throughly crafted packages are designed to provide you with comprehensive insights into your health, empowering you to take proactive steps towards a healthier future. From advanced screenings to personalised consultations, each package is tailored to meet your unique needs and prioritise your long-term health.</p>
                        <div className="btn-box">
                            <Link to='/Contact' className="btn-1">
                                Book Appoinment
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12" data-aos="fade-up-right">
                        <div className="relative">
                            <div className='health-img'>
                                <img src={health} />
                            </div>
                            <div className='health-card py-4 px-4'>
                                <h6 style={{ textAlign: 'center', fontSize: '16px', color: "grey" }}>LIMITED PERIOD ORDER</h6>
                                <h3 style={{ fontSize: '30px', color: "#2e2f62", paddingTop: '10px' }}>Upto 30% Discount On All Health Packages</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="section" data-aos="zoom-in-up">
                <div class="container ">
                    <h1 style={{color:'#2e2f62', textAlign:'center', fontSize:'50px'}}>Choose Your Health Path</h1>
                    <div class="row">
                        <div class="col-md-12 col-lg-4 p-md-0 mt-xl-5 mt-0  ">

                            <div class="table-default table1 grad1 ">

                                <div class="table__header">
                                    <h2 class="table__header--title">Comprehensive Health Check (Male)</h2>
                                    <p className='table_amount'>₹ 6000</p>
                                    <p data-price="4000" class="table__header--price">
                                        <span>₹</span> 4000 /-
                                    </p>
                                </div>

                                <p className='text-left ml-3'>Package Inclusions</p>
                                <div className='d-md-flex d-block'>

                                    <div class="table__content">

                                        <ul class="table__content--list">
                                            <li>CBC With ESR</li>
                                            <li>Urine Routine</li>
                                            <li>FBS/PPBS</li>
                                            <li>HBA1C</li>
                                            <li>BUN</li>
                                            <li>Sr Creatinine</li>
                                            <li >Sr Electrolytes</li>
                                            <li >Liver Function Tests</li>


                                        </ul>

                                    </div>
                                    <div class="table__content">

                                        <ul class="table__content--list">


                                            <li>ECHO</li>
                                            <li>Chest Xray</li>
                                            <li>USG Abdomen & Pelvis</li>
                                            <li>S PSA </li>
                                            <li>Consultation With Physician</li>
                                            <li >Consultation With Dietician</li>

                                        </ul>

                                    </div>
                                </div>



                                <div class="table__footer">
                                    <p className='text-left ' style={{ fontSize: '10px' }}>Liver Function Test includes: Total Bilirubin, Total Protein, Sr Albumin, Sr Globulin, A/G Ratio, GGT/SGOT/SGPT & Alkaline Phosphatase
                                    </p>
                                </div>

                            </div>

                        </div>
                        <div class="col-md-12 col-lg-4 p-md-0 mt-5 ">

                            <div class="table-default table1 recommended grad2">

                                <div class="table__header">
                                    <h2 class="table__header--title">Master Health Check</h2>
                                    <p className='table_amount'>₹ 9000</p>
                                    <p data-price="6000" class="table__header--price">
                                        <span>₹</span> 6000 /-
                                    </p>
                                </div>

                                <p className='text-left ml-3 px-3 px-xl-0'>Package Inclusions</p>
                                <div className='d-md-flex d-block'>

                                    <div class="table__content px-3 px-xl-0">

                                        <ul class="table__content--list">
                                            <li>CBC With ESR</li>
                                            <li>Urine Routine</li>
                                            <li>FBS/PPBS</li>
                                            <li>HBA1C</li>
                                            <li>BUN</li>
                                            <li>Sr Creatinine</li>
                                            <li >Sr Electrolytes</li>
                                            <li>Sr Uric Acid</li>
                                            <li>Sr Calcium</li>
                                            <li>Liver Function Tests</li>
                                            <li>Lipid Profile</li>
                                            <li >Vitamin D3</li>

                                        </ul>

                                    </div>
                                    <div class="table__content px-3 px-xl-0">

                                        <ul class="table__content--list">
                                            <li >USG Abdomen & Pelvis</li>
                                            <li>Spirometry</li>
                                            <li>TSH</li>
                                            <li>ECG</li>
                                            <li>ECHO</li>
                                            <li>Chest Xray</li>
                                            <li>USG Abdomen & Pelvis</li>
                                            <li>S PSA (Male)/ PAP Smear (Female)</li>
                                            <li>Consultation With Physician</li>
                                            <li>Consultation With Dietician</li>
                                            <li >TMT , If Advised</li>
                                        </ul>

                                    </div>
                                </div>
                                <div class="table__footer">
                                    <p className='text-left px-5 px-xl-3 ' style={{ fontSize: '10px' }}>Liver Function Test includes: Total Bilirubin, Total Protein, Sr Albumin, Sr Globulin, A/G Ratio, GGT/SGOT/SGPT & Alkaline Phosphatase
                                        Lipid Profile includes : Sr Cholesterol, HDL Cholesterol, Sr LDL Cholesterol, Sr Triglycerides & Sr VLDL Cholesterol</p>
                                </div>

                            </div>

                        </div>
                        <div class="col-md-12 col-lg-4 p-md-0 mt-5">

                            <div class="table-default table1 grad3">

                                <div class="table__header">
                                    <h2 class="table__header--title">Comprehensive Health Check (Female)</h2>
                                    <p className='table_amount'>₹ 6,500</p>
                                    <p data-price="4000" class="table__header--price">
                                        <span>₹</span> 4,500 /-
                                    </p>
                                </div>

                                <p className='text-left ml-lg-5 ml-0'>Package Inclusions</p>
                                <div className='d-md-flex d-block'>

                                    <div class="table__content ml-lg-3 ml-0">

                                        <ul class="table__content--list">
                                            <li>CBC With ESR</li>

                                            <li>FBS/PPBS</li>
                                            <li>HBA1C</li>
                                            <li>BUN</li>
                                            <li>Chest Xray</li>
                                            <li >PAP Smear</li>
                                            <li >ECHO</li>




                                        </ul>

                                    </div>
                                    <div class="table__content">

                                        <ul class="table__content--list">


                                            <li >Liver Function Tests</li>
                                            <li> Sr Creatinine</li>
                                            <li>USG Abdomen & Pelvis</li>
                                            <li> Sr Electrolytes </li>
                                            <li>Mammography</li>
                                            <li>Consultation With Physician</li>
                                            <li>Consultation With Gynaecologist</li>


                                        </ul>

                                    </div>
                                </div>



                                <div class="table__footer ml-lg-5 ml-0">
                                    <p className='text-left ' style={{ fontSize: '10px' }}>Liver Function Test includes: Total Bilirubin, Total Protein, Sr Albumin, Sr Globulin, A/G Ratio, GGT/SGOT/SGPT & Alkaline Phosphatase & DL Cholesterol
                                    </p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section class="section section-grad mt-5" data-aos="zoom-in-down">
                <div class="container">
                    <h1>Choose Your Health Path</h1>
                    <div class="row package-table">
                        <div class="col-md-12 col-lg-4 package-table">

                            <div class="table-default table3">

                                <div class="table__header">
                                    <img class="table__header--icon" src="https://image.ibb.co/gPbfdG/table3_basic.png" alt="Basic Icon" />
                                    <h2 class="table__header--title" >Diabetic Health Check</h2>
                                    <p className='table_amount'>₹ 6,500</p>
                                    <p data-price="4000" class="table__header--price">
                                        <span>₹</span> 4,500 /-
                                    </p>
                                </div>
                                <p className='text-left '>Package Inclusions</p>
                                <div className='d-md-flex d-block'>

                                    <div class="table__content">

                                        <ul class="table__content--list">
                                            <li>CBC With ESR</li>

                                            <li>FBS/PPBS</li>
                                            <li>HBA1C</li>
                                            <li>ECG</li>
                                            <li>Sr Creatinine</li>
                                            <li >Sr. Potassium</li>

                                            <li>Echo</li>

                                            <li>TSH</li>
                                        </ul>
                                    </div>
                                    <div class="table__content">

                                        <ul class="table__content--list">
                                            <li>Vitamin B12</li>
                                            <li>Urine Routine</li>
                                            <li>Fasting Lipid Profile</li>
                                            <li >Sr. Blood Urea</li>
                                            <li>Consultation With Diabetologist</li>
                                            <li>Consultation With Cardiologist</li>
                                            <li>Consultation With Opthalmologist</li>
                                            <li >Consultation With Dietician</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="table__footer">
                                    <p className='text-left ' style={{ fontSize: '12px' }}>Lipid Profile includes : Sr Cholesterol, HDL Cholesterol, Sr LDL Cholesterol, Sr Triglycerides & Sr VLDL Cholesterol
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 col-lg-4 package-table">

                            <div class="table-default table3 recommended ">

                                <div class="table__header">
                                    <img class="table__header--icon" src="https://image.ibb.co/hBfj5w/table3_premium.png" alt="Standard Icon" />
                                    <h2 class="table__header--title" >Cardiac Health Check</h2>
                                    <p className='table_amount'>₹ 6,500</p>
                                    <p data-price="4000" class="table__header--price">
                                        <span>₹</span> 4,500 /-
                                    </p>
                                </div>

                                <p className='text-left '>Package Inclusions</p>
                                <div className='d-md-flex d-block'>

                                    <div class="table__content">

                                        <ul class="table__content--list">
                                            <li>CBC With ESR</li>

                                            <li>RBS</li>
                                            <li>HBA1C</li>
                                            <li>ECG</li>
                                            <li>Lipid Profile</li>
                                            <li >Chest Xray</li>

                                            <li>Echo</li>


                                        </ul>
                                    </div>
                                    <div class="table__content">

                                        <ul class="table__content--list">
                                            <li>TMT</li>
                                            <li>HS CRP</li>
                                            <li>Consultation With Cardiologist</li>

                                            <li >Consultation With Dietician</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="table__footer">
                                    <p className='text-left ' style={{ fontSize: '12px' }}>Lipid Profile includes : Sr Cholesterol, HDL Cholesterol, Sr LDL Cholesterol, Sr Triglycerides & Sr VLDL Cholesterol
                                    </p>
                                </div>

                            </div>

                        </div>
                        <div class="col-md-12 col-lg-4 package-table">

                            <div class="table-default table3">

                                <div class="table__header">
                                    <img class="table__header--icon" src="https://image.ibb.co/eEytyG/table3_standard.png" alt="Premium Icon" />
                                    <h2 class="table__header--title" >Well Women Care</h2>
                                    <p className='table_amount'>₹ 7,500</p>
                                    <p data-price="4000" class="table__header--price">
                                        <span>₹</span> 5,500 /-
                                    </p>
                                </div>

                                <p className='text-left '>Package Inclusions</p>
                                <div className='d-md-flex d-block'>

                                    <div class="table__content">

                                        <ul class="table__content--list">
                                            <li>CBC With ESR</li>
                                            <li>Urine Routine</li>
                                            <li>FBS/PPBS</li>
                                            <li>BUN</li>

                                            <li>Sr Creatinine</li>
                                            <li >Sr. Potassium</li>

                                            <li>Liver Function Tests</li>

                                            <li>Thyroid Function Test</li>
                                        </ul>
                                    </div>
                                    <div class="table__content">

                                        <ul class="table__content--list">
                                            <li>Lipid Profile</li>
                                            <li>Chest Xray</li>
                                            <li>USG Abdomen & Pelvis</li>
                                            <li >PAP Smear</li>
                                            <li>Mammography</li>
                                            <li>Consultation With Gynaecologist</li>

                                            <li >Consultation With Dietician</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="table__footer">
                                    <p className='text-left ' style={{ fontSize: '12px' }}>Liver Function Test includes: Total Bilirubin, Total Protein, Sr Albumin, Sr Globulin, A/G Ratio, GGT/SGOT/SGPT & Alkaline Phosphatase Lipid Profile includes : Sr Cholesterol, HDL Cholesterol, Sr LDL Cholesterol, Sr Triglycerides & Sr VLDL Cholesterol
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div className='health-back mx-auto'>
                <div className=' text-center py-3'>
                <div class="table__header">
                    <h2 class="table__header--title"><b>Basic Health Check</b></h2>
                    <p className='table_amount'>₹ 2000</p>
                    <p data-price="6000" class="table__header--price" style={{fontSize:'36px'}}>
                        <span >₹</span> 1,200 /-
                    </p>
                </div>
                </div>
                <p className='text-left ml-3 px-3 px-xl-0'>Package Inclusions</p>
                                    <div className='d-md-flex d-block'>

                                        <div class="table__content px-3 px-xl-0">

                                            <ul class="table__content--list">
                                                <li>CBC With ESR</li>
                                                <li>Urine Routine</li>
                                                <li>RBS</li>
                                                

                                            </ul>

                                        </div>
                                        <div class="table__content px-3 px-xl-0">

                                            <ul class="table__content--list">
                                                
                                                <li>ECG</li>
                                                <li>Chest Xray</li>
                                                <li>Consultation With Physician</li>
                                                
                                            </ul>

                                        </div>
                                    </div>
            </div>

        </div>
    )
}

export default HealthPackage;
