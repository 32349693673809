import React from 'react'
import { useState, useEffect } from 'react'
import artho from './assets/images/arthroplasty-icon_64e1996f18dae.png'
import accident from './assets/images/accident-and-emergency-care-icon_64e179ee9a816.png'
import bariatric from './assets/images/bariatric-surgery-cion_64e19a8940edc.png'
import dermatology from './assets/images/dermatology-icon_64e19d1f12b46.png'
import ent from './assets/images/ent-icon_64e19e2dea88a.png'
import cardio from './assets/images/cardiothoracic-and-vascular-surgery-icon_64e19bd454b7e.png'
import arthro from './assets/images/arthroscopy-icon_64e199db64c00.png'
import anaes from './assets/images/anaesthesiology-icon_64e198d96f76c.png' 
import cardiology from './assets/images/Cardiology-Service.png'
import diobetes from './assets/images/Diabetology.png'
import dental from './assets/images/Paediactric_dentistry.png'
import surgery from './assets/images/ortho-icu-1.png'
import neonatology from './assets/images/pediatric_surer.png'
import laboratory from './assets/images/General-Medicine.png'
import gastro from './assets/images/Gastroenterology (1).png'
import pediatric from './assets/images/pediatric_neurology1.png'
import plastic from './assets/images/Plastic-Surgery.png'
import brain from './assets/images/brain-surgery.png'
import obstetrics from './assets/images/Obstetrics.png'
import pulmonology from './assets/images/PULMONOLOGY.png'
import rheumatology from './assets/images/Rheumatology.png'
import radiology from './assets/images/Radiology.png'

const Specialities = () => {
    const [color, setColor] = useState('white');

    useEffect(() => {
      // 👇️ scroll to top on page load
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

  return (
    <div>
     
          <div className="bannerAll " data-aos="zoom-in-up">
            {/* <img src='https://img.freepik.com/free-photo/park-lujiazui-financial-center-shanghai-china_1127-2860.jpg?t=st=1710751715~exp=1710755315~hmac=f6ec9b31abda386d7588f32a77029868afd1770cb6abfda34cd7b1291e236248&w=900' /> */}
            <p className=''>Specialities</p>
          </div>
        <div>
            {/* <h1 className=''>Our Specialist</h1> */}
            <div className="container ">
        <div className="row">
          <h2 className="section-title"></h2>
          <div className="col-md-6 col-xl-3  pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={accident} alt="Image"/>
                </div>
              </div>
              <h3 className="title" >Accident and Emergency Care</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox  gray">
              <div className="special-icon">
                <div className="image-container" style={{ Color: color }}>
                  <img src={artho} alt="Image"/>
                </div>
              </div>
              <h3 className="title">Arthroplasty (Joint Replacement Surgeries)</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={bariatric} alt="Image"/>
                </div>
              </div>
              <h3 className="title"> Bariatric surgery</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={cardio} alt="Image"/>
                </div>
              </div>
              <h3 className="title">Cardiothoracic and Vascular Surgery</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={dermatology} alt="Image"/>
                </div>
              </div>
              <h3 className="title">Dermatology</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={ent} alt="Image"/>
                </div>
              </div>
              <h3 className="title">Ent</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={anaes} alt="Image"/>
                </div>
              </div>
              <h3 className="title"> Anaesthesiology</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={arthro} alt="Image"/>
                </div>
              </div>
              <h3 className="title"> Arthroscopy</h3>
            </div>
          </div>
         
            <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color  }}>
                  <img src={cardiology} />
                </div>
              </div>
              <h3 className="title">Cardiology</h3>
            </div>
          </div>
         
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={dental} alt="Image"/>
                </div>
              </div>
              <h3 className="title"> Dental Care</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={diobetes} alt="Image"/>
                </div>
              </div>
              <h3 className="title"> Diabetes and Endocrinology</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={gastro} alt="Image" />
                </div>
              </div>
              <h3 className="title">  Gastroenterology</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={surgery} alt="Image"/>
                </div>
              </div>
              <h3 className="title"> Orthopaedics</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={laboratory} alt="Image"/>
                </div>
              </div>
              <h3 className="title">Laboratory Medicine</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={neonatology} alt="Image"/>
                </div>
              </div>
              <h3 className="title"> Neonatology</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={radiology} alt="Image"/>
                </div>
              </div>
              <h3 className="title"> Radiology</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={rheumatology} alt="Image"/>
                </div>
              </div>
              <h3 className="title"> Rheumatology</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={pulmonology} alt="Image"/>
                </div>
              </div>
              <h3 className="title"> Pulmonology</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={plastic} alt="Image"/>
                </div>
              </div>
              <h3 className="title">Plastic-Surgery</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={brain} alt="Image"/>
                </div>
              </div>
              <h3 className="title">Brain-surgery</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={obstetrics} alt="Image"/>
                </div>
              </div>
              <h3 className="title">Obstetrics</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
              <div className="image-container" style={{ Color: color }}>
                  <img src={pediatric} alt="Image"/>
                </div>
              </div>
              <h3 className="title">Pediatric_Neurology</h3>
            </div>
          </div>

        </div>
      </div>
        </div>
         
    </div>
  )
}

export default Specialities;
