import bg from "./assets/images/client.jpg";
import underline from "./assets/images/underline.png";
import client from "./assets/images/client.jpg";
import hptl1 from "./assets/images/hptl1.jpg";
import needle from "./assets/images/needle.jpeg";
import blood from "./assets/images/bloodtest.jpeg";
import icu from "./assets/images/icu.jpeg";
import { useEffect, useState } from "react";
import tick from "./assets/images/tick.png";
import aboutBanner from "./assets/images/slider-bg-1.jpg";
import quote from "./assets/images/quote.png";
import next from "./assets/images/next.png";
import { LiaMedalSolid } from "react-icons/lia";
import { FaNotesMedical } from "react-icons/fa6";
import { FaRegHandshake } from "react-icons/fa";
import { FaHandsHoldingChild } from "react-icons/fa6";
import { GiAchievement } from "react-icons/gi";
import { Link } from 'react-router-dom';
import hospitalBan3 from './assets/images/hospital-banner3.jpg'

function About() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);



  const [currentIndex, setCurrentIndex] = useState(0);
  const contentList = [
    {
      title: "Our Mission",
      text: "Healthcare, In Many Places In India Has Become Purely Transactional. At Altius, We Want To Deliver A Patient-Centric, Care Focussed Healthcare Experience While At The Same Time Maintaining A Higher Standard Of Care And Service Excellence",
      imageUrl:
        "https://img.freepik.com/free-photo/medical-team-stacking-hand_587448-4731.jpg?t=st=1711543086~exp=1711546686~hmac=e7e9f0d7812ce5ac634efd4d6ec1fe1619cfa9b24d91a44088a43ad8f896246f&w=826",
    },
    {
      title1: "Our Vision",
      text1: "At Sahaya Annai, Our Vision Is To Bring Positive Impact In The Life Of People By Providing Empathic, Affordable, High Quality Healthcare. We Aspire To Bring Smiles On The Faces Of Atleast 1 Million Indians Over The Next Decade",
      imageUrl:
        "https://img.freepik.com/premium-photo/world-health-day-medical-banner-vibrant-doctor-day-illustration_1037362-19508.jpg?size=626&ext=jpg&ga=GA1.1.1803359253.1711542548&semt=ais",
    },
  ];

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? contentList.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === contentList.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      {/* <div className="bannerAboutAll ">
            <p >We are here for your care. </p>
          <h1 className="pt-3">About Us</h1>
          </div> */}

      <section className="slider_section hero_area">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          {/* <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        </ol> */}
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-5 offset-md-1" data-aos="fade-left">
                    <div className="detail-box">
                      <h1 style={{ fontSize: '40px' }}>About <span >Sahaya Annai</span>  Hospital</h1>
                      <p>
                        Sahaya Annai Hospitals, Madurai, has evolved as a leader
                        in the healthcare system because of its integrated model
                        that combines all services under one roof. 
                        Our world-class chain of multi-specialty hospitals aims
                        to treat people, not diseases. We use cutting-edge
                        technology and harness health for all our patients so
                        they can live their best lives possible. The Sahaya
                        Annai team has the best medical professionals providing
                        excellent care, coupled with their rich experience and
                        expertise using state-of-the-art technology. The
                        hospital also offers comprehensive heart care,
                        diagnostics, and treatment. 
                      </p>
                      <div className="btn-box">
                        <Link to='/Contact' className="btn-1">
                          Book Appoinment
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6" data-aos="fade-right">
                    <div className="img-box">
                    <img src={hospitalBan3} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="client_section">
        <div className="container">
          <div className="heading_container">
            <h2>What About Us</h2>
            <p>
              "Sahaya Annai Hospital delivers compassionate, high-quality
              healthcare services with a focus on patient well-being, advanced
              technology, and skilled medical professionals for optimal
              treatment outcomes."
            </p>
          </div>
          {/* <div
            id="carouselExampleControls"
            className="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="box">
                  <div class="img-box">
                    <img
                      src={contentList[currentIndex].imageUrl}
                      alt={contentList[currentIndex].title}
                    />
                  </div>
                  <div class="detail-box">
                    <h4>{contentList[currentIndex].title}</h4>
                    <p>{contentList[currentIndex].text}</p>
                    <img src={quote} />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel_btn-box">
              <a
                class="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
                onClick={handlePrevious}
              >
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
                onClick={handleNext}
              >
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div> */}
          <div className="container  mt-5 ">
            <div className="row gx-5">
              <div className="col-lg-6 col-md-12 mt-2" data-aos="fade-up-left">
                <div className="p-3 our-mission  ">
                <h4>Our Mission</h4>
                    <p>Healthcare, In Many Places In India Has Become Purely Transactional. At Altius, We Want To Deliver A Patient-Centric, Care Focussed Healthcare Experience While At The Same Time Maintaining A Higher Standard Of Care And Service Excellence</p>
                    <img src={quote} />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mt-5 mt-lg-2" data-aos="fade-up-right">
                <div className="p-3 our-mission">
                <h4>Our Vision</h4>
                    <p>At Sahaya Annai, Our Vision Is To Bring Positive Impact In The Life Of People By Providing Empathic, Affordable, High Quality Healthcare. We Aspire To Bring Smiles On The Faces Of Atleast 1 Million Indians Over The Next Decade"</p>
                    <img src={quote} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="text-center p-4 weLive">
          <p className="text-center">WHAT MAKES US DIFFERENT</p>
          <h1 className="">The Values We Live By</h1>
        </div>

        <div className="container px-5" >

          <div className="row g-5" >

            <div className="col-md-12 col-lg-6" data-aos="fade-up-left" >
              <div className=" integraty-page">
                <p className="integraty-icon"><GiAchievement /></p>
                <div className="content">
                  <h2>Quality</h2>
                  <p>At Sahaya Annai Hospital, we prioritize quality in every aspect of healthcare, from cutting-edge medical treatments to compassionate patient care, ensuring our commitment to excellence is evident in every healing touch and diagnostic precision.</p>
                </div>

              </div>
            </div>
            <div className="col-md-12 col-lg-6" data-aos="fade-up-right">
              <div className="integraty-page">
                <p className="integraty-icon"><FaNotesMedical /></p>
                <div className="content">
                  <h2>Excellence</h2>
                  <p>Sahaya Annai Hospital relentlessly pursues excellence in medical innovation, personalized patient care, and staff expertise. We set the bar high, aiming not just to meet but to exceed expectations, delivering healthcare that stands out for its exceptional standards.</p>
                </div>

              </div>
            </div>
            <div className="col-md-12 col-lg-6" data-aos="fade-up-left">
              <div className=" integraty-page">
                <p className="integraty-icon"><FaHandsHoldingChild /></p>
                <div className="content">
                  <h2>Integrity</h2>
                  <p>Our foundation rests on unwavering integrity at Sahaya Annai  Hospital, where honesty, ethics, and transparency guide our interactions. We prioritize trust-building with patients, fostering a healthcare environment built on the principles of accountability and reliability.</p>
                </div>

              </div>
            </div>
            <div className="col-md-12 col-lg-6" data-aos="fade-up-right">
              <div className="integraty-page">
                <p className="integraty-icon"><FaRegHandshake /></p>
                <div className="content">
                  <h2>Dignity</h2>
                  <p>Respecting the inherent worth and dignity of every individual, Sahaya Annai Hospital ensures a compassionate and inclusive healthcare experience. We champion a patient-centered approach that upholds the dignity and autonomy of each person, fostering a healing environment built on empathy and understanding.</p>
                </div>

              </div>
            </div>

          </div>

        </div>

      </section>

      
    </>
  );
}
export default About;
