import "./css/main.css";
import "./css/responsive.css";
import "./css/bootstrap.css";
import { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SlideShow from './SlideShow';

// import logo from '../assets/images/hospital-logo.png';
// import { Carousel, Container, Row, Col } from 'react-bootstrap';
import "./css/main.scss";
import logo from './assets/images/hospital-logo.png';
// import reception from './assets/images/reception.png';
import underline from './assets/images/underline.png';
// import reception from "./assets/images/hospital-logo.png"
// import reception from "../assets/images/hospital-logo.png";
// import underline from "./assets/images/underline.png";
// import "../css/main.css.map";
import bg from "./assets/images/hero-bg.png";
import client from './assets/images/client.jpg';
import plus from './assets/images/plus.png';
// import { useNavigate } from "react-router-dom";
import hpt2 from "./assets/images/hpt2.jpg";
import hptl1 from './assets/images/hptl1.jpg';
import needle from './assets/images/needle.jpeg';
import blood from './assets/images/bloodtest.jpeg';
import icu from './assets/images/icu.jpeg';
import reception from './assets/images/reception1.png';
import pharmacy from './assets/images/pharmacy1.avif';
import emargency from './assets/images/emargency.jpeg';
// import male from './assets/images/doctormale.jpeg';
// import female from './assets/images/doctorfemale.jpeg';
// import { useFormik,Formik,Form} from "formik";
// import { Formvalidation } from "./formvalidation";
import { useFormik } from 'formik';
import { basicSchema } from "./Formvalidation";
// import React, { useState } from 'react';
import tick from "./assets/images/tick.png";
// import doctor from './assets/images/ladydoctor.avif'
import doctorbg from './assets/images/doctorbg.jpg';
import doctor1 from './assets/images/doctor1.png'
import doctorback from './assets/images/doctorback.png'
import React, { useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import icu2 from './assets/images/icu2.jpg';
import genral from './assets/images/genral.jpeg';
import prvt from './assets/images/prvt.jpeg';
import operation from './assets/images/operation.jpeg';
import lab from './assets/images/lab.jpeg';
import dental from './assets/images/dental.jpeg';
import sicu from './assets/images/sicu.png';
import about1 from './assets/images/about-1.jpg';
import about2 from './assets/images/about-2.jpg';
import circle from './assets/images/circle-design.png';
import admitgirl from './assets/images/admin-women12.jpg';
import homehospital from './assets/images/home-hospial.jpg';

import { FaUserDoctor } from "react-icons/fa6";
import testimonial from './assets/images/testimonial.png'
import section from './assets/images/section-img.png'
import { FaAmbulance } from "react-icons/fa";
import { FaSuitcaseMedical } from "react-icons/fa6";
import { FaStethoscope } from "react-icons/fa6";
import { FaLungsVirus } from "react-icons/fa";
import { LuCalendarClock } from "react-icons/lu";
import { FaNotesMedical } from "react-icons/fa";
import { MdMedicalInformation } from "react-icons/md";
import { FaHandHoldingMedical } from "react-icons/fa";
import { FaBedPulse } from "react-icons/fa6";
import artho from './assets/images/arthroplasty-icon_64e1996f18dae.png'
import accident from './assets/images/accident-and-emergency-care-icon_64e179ee9a816.png'
import bariatric from './assets/images/bariatric-surgery-cion_64e19a8940edc.png'
import dermatology from './assets/images/dermatology-icon_64e19d1f12b46.png'
import ent from './assets/images/ent-icon_64e19e2dea88a.png'
import cardio from './assets/images/cardiothoracic-and-vascular-surgery-icon_64e19bd454b7e.png'
import arthro from './assets/images/arthroscopy-icon_64e199db64c00.png'
import anaes from './assets/images/anaesthesiology-icon_64e198d96f76c.png'
import { IoIosStar } from "react-icons/io";
import footerlogo from  './assets/images/footer_logo_bg.svg'
import hospitalBan1 from './assets/images/hospital-banner1.jpg'
import hospitalBan2 from './assets/images/hospital-banner2.jpg'



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';



const onSubmit = (values, actions) => {
  console.log(values)
  console.log(actions);
  ;
}

function Home() {
  
  const phoneNumber = '6369313880'; // Replace this with the actual phone number
  const messageto = 'Hello, Sahaya Annai Hospital, how can i help you.'; // Replace with your desired message

  // Construct the WhatsApp link
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(messageto)} `;
  const navigate = useNavigate();

  const [color, setColor] = useState('white');


  const { values, handleChange, touched, handleBlur, errors, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      phonenumber: '',
      email: '',
      password: '',
      message: '',
    },
    validationSchema: basicSchema,
    onSubmit,
  });
  console.log(touched);


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [number, setNumber] = useState('');

  const phonenumber = "+916369313880";

  const sendWhatsApp = () => {
    const url = `https://wa.me/${phonenumber}?text=
    Name : ${name}%0a
    Email : ${email}%0a
    Password: ${password}%0a
    PhoneNumber: ${number}%0a
    Message : ${message}%0a%0a`;

    window.open(url, '_blank').focus();
  };


  const counterRef = useRef(null);

  useEffect(() => {
    const animateCounter = () => {
      const counters = document.querySelectorAll('.counter-value');
      counters.forEach((counter) => {
        const updateCounter = () => {
          const targetValue = +counter.getAttribute('data-target');
          const count = +counter.innerText;

          const increment = targetValue / 100;

          if (count < targetValue) {
            counter.innerText = Math.ceil(count + increment);
            setTimeout(updateCounter, 35);
          } else {
            counter.innerText = targetValue;
          }
        };

        updateCounter();
      });
    };

    animateCounter();
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);




  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // counter
  const Counter = ({ initialValue, delay, duration, stopValue }) => {
    const [count, setCount] = useState(initialValue);

    useEffect(() => {
      const interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount >= stopValue) {
            clearInterval(interval);
            return prevCount;
          }
          return prevCount + 1;
        });
      }, 1); // Set a lower delay value, such as 10 milliseconds

      return () => clearInterval(interval);
    }, [stopValue]);

    return <span>{count}</span>;
  };


  return (
    <div>
      {/* header start */}
      {/* <Header /> */}
      {/* header end */}

      {/* banner start  */}

      {/* <div className="container-fluid  d-none d-lg-block bg-image">
        <div className="row"> */}

      {/* <div className="   hero-left bg-image">


      </div> */}

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide className="hero-left ">
          <img src={hospitalBan1} />
        </SwiperSlide>
        <SwiperSlide className="hero-left ">
        <img src={hospitalBan2} />
        </SwiperSlide>
        <SwiperSlide className="hero-left ">
        <img src={hospitalBan1} />
        </SwiperSlide>
        
      </Swiper>

      <div>       
      </div>
      {/* <!-- Start Feautes --> */}
      <section class="Feautes section mt-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title">
                <h1>Sahaya Annai Multi Cure Speciality Hospital</h1>
                {/* <img src={section} /> */}

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-12" data-aos="zoom-in-left">

              <div class="single-features">
              <Link to='/Contact'>
              <div class="signle-icon">
                  <LuCalendarClock />							</div>
                <h3>Request Appointment</h3></Link>
                
              </div>

            </div>
            <div class="col-lg-3 col-12" data-aos="zoom-in-up">

              <div class="single-features">
              <Link to='/Ourdoctor'>
                <div class="signle-icon">
                  <FaUserDoctor />
                </div>
                <h3>Find Doctor</h3></Link>
              </div>

            </div>
            <div class="col-lg-3 col-12" data-aos="zoom-in-up">

              <div class="single-features ">
              <a href={whatsappUrl} target='blank'>
                <div class="signle-icon">
                  <MdMedicalInformation />
                </div>
                <h3>schedule Diagnostic Test</h3></a>
              </div>

            </div>
            <div class="col-lg-3 col-12" data-aos="zoom-in-right">

              <div class="single-features last">
              <a href={whatsappUrl} target='blank'>
                <div class="signle-icon">
                  <FaNotesMedical />
                </div>
                <h3>Check Reports</h3></a>
              </div>

            </div>
          </div>
        </div>
      </section>

      {/* about start */}
      <section class="about_section layout_margin-bottom" id="About" style={{ backgroundImage: './ass' }}>
        <div class="heading_container heading_center ">
          <div className="nurseHome-icon ">
            <h1 className="pt-5     about-h1  ">
              ABOUT
            </h1>
          </div>
          <img src={underline} style={{ width: "300px" }}></img>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-6 abt-bg py-5 " data-aos="fade-right" >
              <div class="aboutgrid right-0 ">
                <img src={circle} />
                <div class="one">
                </div>
                <div class="one">
                  <img src={about1} />
                </div>
                <div class="two">
                  <img src={about2} />
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-6 about-cnt" data-aos="fade-left"  >
              <div class="px-lg-5 ">

                {/* <h5 class="border-ab-us fw-bold "> <span>About Us</span></h5> */}

                <p class="description fw-bold mt-4" >Sahaya Annai Hospital was established in the year 1975. This is located at Koodal Nagar in the district of Madurai, Tamilnadu State in Indian soil. The project proceedings will be initiated at Sahaya Annai Hospital. People become victims to the ailments such as dengue, diabetic, hypertension, cardiac problem, typhoid, asthma etc very easily as their health is weak due to malnourishment. </p><br></br>
                <h5 class="card-title mb-5">Special Feature of SahayaAnnai Hospital</h5>

                <p class="description d-flex"><img src={tick} class="tick d-flex"></img>It's a service oriented and charity hospital.</p><br></br>
                <p class="description d-flex"><img src={tick} class="tick d-flex"></img>It ensures the safety measures of safeguarding the health of the patients.</p><br></br>

                <p class="description d-flex"><img src={tick} class="tick d-flex"></img>Top priority, preferential option for the poor and the under privileged are the
                  prime focus of the hospital.
                </p>
                <a onClick={() => navigate("/About")} ><span class="read-more">Read More <i class="bi bi-arrow-right"></i></span></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about end */}

      {/* <!-- Start Fun-facts --> */}
      <div id="fun-facts" class="fun-facts section overlay px-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-12" data-aos="zoom-out-left">

              <div class="single-fun">
                <i class="bi bi-house-add-fill"></i>
                <div class="content">
                  <span class="counter " >
                    <Counter initialValue={1} delay={50} time={2000} stopValue={50} /> 
                  </span>
                  <p> Years of Experience</p>
                </div>
              </div>

            </div>
            <div class="col-lg-3 col-md-6 col-12" data-aos="zoom-out-down">

              <div class="single-fun">
                <i class="bi bi-people-fill"></i>
                <div class="content">
                  <span class="counter d-flex ">
                    <Counter initialValue={1} delay={50} time={2000} stopValue={50}  /> <span >+</span> 
                  </span>
                  <p>Specialist Doctors</p>
                </div>
              </div>

            </div>
            <div class="col-lg-3 col-md-6 col-12" data-aos="zoom-out-down">

              <div class="single-fun">
                <i> <FaHandHoldingMedical /></i>
                <div class="content">
                  <span class="counter d-flex ">
                    <Counter initialValue={1} delay={10} time={2000} stopValue={1} /> <span>million +</span>
                  </span>
                  <p> Patients Served</p>
                </div>
              </div>

            </div>
            <div class="col-lg-3 col-md-6 col-12 " data-aos="zoom-out-right">

              <div class="single-fun">
                <i><FaBedPulse /></i>
                <div class="content">
                  <span class="counter d-flex">
                    <Counter initialValue={100} delay={1} time={2000} stopValue={1000} /> <span>+</span>
                  </span>
                  <p>Surgeries Performed</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* Specialist */}
      <div className="heading_container heading_center px-5 mt-5">
        <div className="nurseHome-icon pt-5">
          <h1 className=" pt-5 -pl-5   about-h1  ">
            SPECIALITIES
          </h1>
        </div>
        <img src={underline} style={{ width: "300px" }}></img>
      </div>
      <div className="container ">
        <div className="row">
          <h2 className="section-title"></h2>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
                <div className="image-container" style={{ Color: color }}>
                  <img src={accident} alt="Image" />
                  
                </div>
              </div>
              <h3 className="title" >Accident and Emergency Care</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            <div className="specialBox  gray">
              <div className="special-icon">
                <div className="image-container" style={{ Color: color }}>
                  <img src={artho} alt="Image" />
                </div>
              </div>
              <h3 className="title">Arthroplasty (Joint Replacement Surgeries)</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
                <div className="image-container" style={{ Color: color }}>
                  <img src={bariatric} alt="Image" />
                </div>
              </div>
              <h3 className="title"> Bariatric surgery</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
                <div className="image-container" style={{ Color: color }}>
                  <img src={cardio} alt="Image" />
                </div>
              </div>
              <h3 className="title">Cardiothoracic and Vascular Surgery</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
                <div className="image-container" style={{ Color: color }}>
                  <img src={dermatology} alt="Image" />
                </div>
              </div>
              <h3 className="title">Dermatology</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
                <div className="image-container" style={{ Color: color }}>
                  <img src={ent} alt="Image" />
                </div>
              </div>
              <h3 className="title">Ent</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
                <div className="image-container" style={{ Color: color }}>
                  <img src={anaes} alt="Image" />
                </div>
              </div>
              <h3 className="title"> Anaesthesiology</h3>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 pb-5"  data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
            <div className="specialBox px-5 gray">
              <div className="special-icon">
                <div className="image-container" style={{ Color: color }}>
                  <img src={arthro} alt="Image" />
                </div>
              </div>
              <h3 className="title"> Arthroscopy</h3>
            </div>
          </div>
          <div className="btn-book mx-auto">
            <a onClick={() => navigate("/Specialities ")} >
              View All
            </a>
          </div>


        </div>
      </div>

      {/* our doctor start */}
      <div id="Ourdoctor ">
        <section className="doctor_section layout_padding mt-5">
          <div className="container">
            <div className="heading_container heading_center">
              <h1 className="doc-h1 mb-5">
                OUR DOCTORS
              </h1>
              {/* <p className="col-md-10 mx-auto px-0 my-5"
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                Incilint sapiente illo quo praesentium officiis laudantium nostrum, ad adipisci cupiditate sit, quisquam aliquid. Officiis laudantium fuga ad voluptas aspernatur error fugiat quos facilis saepe quas fugit, beatae id quisquam.
              </p> */}
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div className="our-team">
                  <div className="pic">
                    <img src={client} />
                  </div>
                  <div className="team-content">
                  <h3 class="title">Dr. T.ARUN PRASATH <span style={{fontSize:'8px'}}>MD, FIPM, F.DIAB,</span></h3>
                    <span class="post"> தலைமை மருத்தவ அதிகாரி (CMO) </span>
                    <span class="post">  அதிதீவிர சிகிச்சை, வலி நிவாரணம் மற்றும் மயக்கவியல் நிபுணர்</span>
                  </div>
                  <ul className="social">
                    <li><a href="#" className="bi bi-facebook"></a></li>
                    <li><a href="#" className="bi bi-twitter"></a></li>
                    <li><a href="#" className="bi bi-google"></a></li>
                    <li><a href="#" className="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-3 " data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div class="our-team">
                  <div class="pic">
                    <img src={client} />
                  </div>
                  <div class="team-content">
                    <h3 class="title">Dr. VALLI DEVI <span style={{fontSize:'8px'}}>MD., PHYSICIAN</span></h3>
                    <span class="post"> பொது மருத்துவ சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul class="social">
                    <li><a href="#" class="bi bi-facebook"></a></li>
                    <li><a href="#" class="bi bi-twitter"></a></li>
                    <li><a href="#" class="bi bi-google"></a></li>
                    <li><a href="#" class="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div className="our-team">
                  <div className="pic">
                    <img src={client} />
                  </div>
                  <div className="team-content">
                  <h3 class="title">Dr.SYED AHAMED KAMIL <span style={{fontSize:'8px'}}>M.S., (GENERAL SURGEON), FIAGES</span></h3>
                    <span class="post">பொது அறுவை சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul className="social">
                    <li><a href="#" className="bi bi-facebook"></a></li>
                    <li><a href="#" className="bi bi-twitter"></a></li>
                    <li><a href="#" className="bi bi-google"></a></li>
                    <li><a href="#" className="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-lg-3" data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div className="our-team">
                  <div className="pic">
                    <img src={client} />
                  </div>
                  <div className="team-content">
                  <h3 class="title">Dr. SARAVANAN <span style={{fontSize:'8px'}}>MD, DM (CARDIOLOGIST)</span></h3>
                    <span class="post"> இருதய நோய் சிகிச்சை நிபுணர்</span>
                  </div>
                  <ul className="social">
                    <li><a href="#" className="bi bi-facebook"></a></li>
                    <li><a href="#" className="bi bi-twitter"></a></li>
                    <li><a href="#" className="bi bi-google"></a></li>
                    <li><a href="#" className="bi bi-instagram"></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="btn-box">
              <a onClick={() => navigate("/Ourdoctor")} >
                View All
              </a>
            </div>
          </div>
        </section>
      </div>
      {/* our doctor end */}

      {/* <!-- TESTIMONIALS --> */}
      <section className="client_section layout_padding-bottom" data-aos="zoom-in-up">

      <div class="heading_container heading_center">
                    <h2>
                      Testimonial
                    </h2>
                    <img src={underline} style={{ width: "300px" }}></img>
                  </div>

                  <div className="text-center p-3 ">
                    <h1 style={{ color:'var(--mainprimary-color)', fontWeight:'bold' }}>Happy Patients</h1>
                  </div>

      <div class="slider container">
        <div class="slide-track gap-5">
        <div class="slide">
    <div class="single-testimonial-box">
						<div class="testimonial-description">
							<div class="testimonial-info">
								{/* <div class="testimonial-img">
									<img src={review1} alt="clients" />
								</div> */}
								<div class="testimonial-person">
									<h2>Kamal Raj k</h2>
									<h4>Madurai</h4>
									<div class="testimonial-person-star">
                  <IoIosStar />
									<IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
									</div>
								</div>
							</div>
							<div class="testimonial-comment">
								<p>
									The treatment doctors and the nursing staff have given me the best treatment and I am really happy with the dedicated service here at Sahaya Annai Hospital, I thank all of them.
								</p>
							</div>
						</div>
					</div>
    </div>
    <div class="slide">
    <div class="single-testimonial-box">
						<div class="testimonial-description">
							<div class="testimonial-info">
								{/* <div class="testimonial-img">
									<img src={review2} alt="clients" />
								</div> */}
								<div class="testimonial-person">
									<h2>Vignesh S</h2>
									<h4>Trichy</h4>
									<div class="testimonial-person-star">
										
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
									</div>
								</div>
							</div>
							<div class="testimonial-comment">
								<p>
								I'm pleased with the medical care, attentive doctors, and caring nurses at Sahaya Annai Hospital. They've provided exceptional service and support.
								</p>
							</div>
						</div>
					</div>
    </div>
    <div class="slide">
    <div class="single-testimonial-box">
						<div class="testimonial-description">
							<div class="testimonial-info">
								{/* <div class="testimonial-img">
									<img src={review3} alt="clients" />
								</div> */}
								<div class="testimonial-person">
									<h2>Vinoth kumar M</h2>
									<h4>Dindigal</h4>
									<div class="testimonial-person-star">
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
									
									</div>
								</div>
							</div>
							<div class="testimonial-comment">
								<p>
                We are satisfied with the medical care and expertise of the doctors. The nursing staff is commendable. Sahaya Annai Hospital has provided excellent service to us.
								</p>
							</div>
						</div>
					</div>
    </div>
    <div class="slide">
    <div class="single-testimonial-box">
						<div class="testimonial-description">
							<div class="testimonial-info">
								{/* <div class="testimonial-img">
									<img src={review4} alt="clients" />
								</div> */}
								<div class="testimonial-person">
									<h2>Swetha Hari S</h2>
									<h4>Madurai</h4>
									<div class="testimonial-person-star">
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
									</div>
								</div>
							</div>
							<div class="testimonial-comment">
								<p>
									We are happy with the quality of the treatment and Doctor's treatment.Sisters here are good. Sahaya Annai Hospital has done well for me.
								</p>
							</div>
						</div>
					</div>
    </div>
    <div class="slide">
    <div class="single-testimonial-box">
						<div class="testimonial-description">
							<div class="testimonial-info">
								{/* <div class="testimonial-img">
									<img src={review1} alt="clients" />
								</div> */}
								<div class="testimonial-person">
									<h2>Kavitha P</h2>
									<h4>Madurai</h4>
									<div class="testimonial-person-star">
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
									</div>
								</div>
							</div>
							<div class="testimonial-comment">

								<p>
                Comprehensive medical services including surgeries, diagnostics, specialized treatments, patient care, advanced technology, and experienced healthcare professionals.
								</p>
							</div>
						</div>
					</div>
    </div>
    <div class="slide">
    <div class="single-testimonial-box">
						<div class="testimonial-description">
							<div class="testimonial-info">
								{/* <div class="testimonial-img">
									<img src={review3} alt="clients" />
								</div> */}
								<div class="testimonial-person">
									<h2>Tangam H</h2>
									<h4>Selam</h4>
									<div class="testimonial-person-star">
                  <IoIosStar />
										<IoIosStar />
										<IoIosStar />
										<IoIosStar />
										
									</div>
								</div>
							</div>
							<div class="testimonial-comment">
								<p>
                Excellence in healthcare with state-of-the-art facilities, experienced medical professionals, advanced treatments, patient-centric care, trusted reputation, and commitment to quality service.
								</p>
							</div>
						</div>
					</div>
    </div>
        </div>
      </div>
      </section>


      {/*administrators start*/}
      <section className=" my-3" >
        <div >
          <div className="container pt-5">
            <div className="heading_container heading_center">
              <div className="nurseHomeAdmin-icon pt-5 px-2">
                <h1 className="  pt-5   admin-h1  ">
                  ADMINISTRATORS LIST
                </h1>
              </div>
              <img src={underline} style={{ width: "300px" }}></img>

            </div>

            <div class="container overflow-hidden text-center">
              <div class="row gx-5">
                <div class="col-md-12 col-lg-6" data-aos="fade-left">
                  <div className="mt-5">
                    <div class="serviceBox orange">
                      <div class="service-icon">
                        <span><FaUserDoctor /></span>
                      </div>
                      <h3 class="title">Sr.Mary Ralph FBS</h3>
                      <h3 class="title">2012 - 2013</h3>
                      <p class="description"> Handling billing and insurance-related tasks, processing payments, addressing financial inquiries.</p>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div class="serviceBox pink">
                      <div class="service-icon">
                        <span><FaUserDoctor /></span>
                      </div>
                      <h3 class="title">Sr.Mariya Goretti FBS</h3>
                      <h3 class="title">2013 - 2016</h3>
                      <p class="description">Greeting and assisting patients, managing phone inquiries, maintaining a welcoming front desk.</p>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div class="serviceBox orange">
                      <div class="service-icon">
                        <span><FaUserDoctor /></span>
                      </div>
                      <h3 class="title">Sr. Elizanath Rani FBS</h3>
                      <h3 class="title">2016 - 2018</h3>
                      <p class="description">Appointment scheduling, coordinating staff schedules, managing paperwork.</p>
                    </div>
                  </div>
                </div>
                <div class="col" data-aos="fade-right">
                  <div>
                  <div className="mt-5">
                    <div class="serviceBox pink">
                      <div class="service-icon">
                        <span><FaUserDoctor /></span>
                      </div>
                      <h3 class="title">Sr.SahayaArokiya Jeyanthi FBS</h3>
                      <h3 class="title">2018 - Present</h3>
                      <p class="description"> Handling billing and insurance-related tasks, processing payments, addressing financial inquiries.</p>
                    </div>
                  </div>
                    <img src={admitgirl} className='admin-photo' /></div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </section>
      {/*administrators end*/}




      {/* contact us start */}
      {/* <div id="Contact">
        <section class="contact_section layout_padding  contact-bg contact-layout">
          <div class="container">
            <div class="heading_container heading_center my-5">
              <h2 >
                Contact us
              </h2>
              <img src={underline} style={{ width: "300px" }}></img>
            </div>
            <div class="row ">
              <div class="col-md-6" data-aos="fade-right">

                <div class="form_container contact-form">
                  <div class="form-container">
                    <div class="form-icon">
                      <i class="bi bi-arrow-right-circle-fill" onClick={() => navigate("/Contact")} ></i>
                    </div>
                    <form class="form-horizontal" >
                      <div class="heading_container">
                        <h2>
                          Get In Touch
                        </h2>
                      </div>
                      <div className="form-group">
                        <span className="input-icon"><i className="bi bi-person-fill"></i></span>
                        <input class="border-0" type="text" id='name' placeholder="Name"
                          className={errors.name && touched.name ? 'input-error' : 'form-control'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name} />
                        {errors.name && touched.name && <p className='error'>{errors.name}</p>}
                      </div>
                      <div className="form-group">
                        <span className="input-icon"><i className="bi bi-telephone-fill"></i></span>
                        <input type="number" id='phonenumber' placeholder="Phone Number"
                          className={errors.phonenumber && touched.phonenumber ? 'input-error' : 'form-control'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phonenumber} />
                        {errors.phonenumber && touched.phonenumber && <p className='error'>{errors.phonenumber}</p>}
                      </div>
                      <div className="form-group">
                        <span className="input-icon" ><i className="bi bi-envelope-fill"></i></span>
                        <input type="email" id="email" placeholder="Email Address"
                          className={errors.email && touched.email ? 'input-error' : 'form-control'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email} />
                        {errors.email && touched.email && <p className='error'>{errors.email}</p>}
                      </div>
                      <div className="form-group">
                        <span className="input-icon"><i className="bi bi-lock-fill"></i></span>
                        <input type="password" id='password' placeholder="Password"
                          className={errors.password && touched.password ? 'input-error' : 'form-control'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password} />
                        {errors.password && touched.password && <p className='error'>{errors.password}</p>}
                      </div>
                      <div className="form-group">
                        <textarea rows="4" cols="120" placeholder="Message" id='message'
                          className={errors.message && touched.message ? 'input-error ' : 'form-control'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message} ></textarea>
                        {errors.message && touched.message && <p className='error'>{errors.message}</p>}
                      </div>
                      <button className="btn signin" onClick={sendWhatsApp}>Send Message <i className="bi bi-arrow-right"></i></button>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-6 p-4" data-aos="fade-left" style={{ border: "5px solid #42F5d4" }}>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15698.974164883793!2d77.96945790000001!3d10.3623792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00aa57a54881e3%3A0x765a14068982e6e7!2sDindigul%2C%20Tamil%20Nadu%20624001!5e0!3m2!1sen!2sin!4v1705054564332!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{ width: '100%', height: '100%' }}></iframe>

              </div>

            </div>

          </div>
        </section>


      </div> */}
      {/* contact us end */}

      {/* client start */}
      {/* <div class="my-5">
        <section class="client_section layout_padding-bottom" data-aos="zoom-in">

          <div class="container px-4 text-center">
            <div class="row gx-5">
              <div class="col-lg-1 col-md-12">
                <div class="pt-lg-5 mt-lg-5">
                  <img src={testimonial} />
                </div>
              </div>
              <div class="col-lg-11 col-md-12">
                <div class="p-3">
                  <div class="heading_container heading_center">
                    <h2>
                      Testimonial
                    </h2>
                    <img src={underline} style={{ width: "300px" }}></img>
                  </div>
                  <div id="carouselExample2Controls" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <div class="row">
                          <div class="col-md-11 col-lg-10 mx-auto">
                            <div class="box">
                              <div class="img-box">
                                <img src={client} />
                              </div>
                              <div class="detail-box">
                                <div class="name">
                                  <h6>
                                    Alan Emerson
                                  </h6>
                                </div>
                                <p>
                                  Enim consequatur odio assumenda voluptas voluptatibus esse nobis officia. Magnam, aspernatur nostrum explicabo, distinctio laudantium delectus deserunt quia quidem magni corporis earum inventore totam consectetur corrupti! Corrupti, nihil sunt? Natus.
                                </p>
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="row">
                          <div class="col-md-11 col-lg-10 mx-auto">
                            <div class="box">
                              <div class="img-box">
                                <img src={client} alt="" />
                              </div>
                              <div class="detail-box">
                                <div class="name">
                                  <h6>
                                    Alan Emerson
                                  </h6>
                                </div>
                                <p>
                                  Enim consequatur odio assumenda voluptas voluptatibus esse nobis officia. Magnam, aspernatur nostrum explicabo, distinctio laudantium delectus deserunt quia quidem magni corporis earum inventore totam consectetur corrupti! Corrupti, nihil sunt? Natus.
                                </p>
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="row">
                          <div class="col-md-11 col-lg-10 mx-auto">
                            <div class="box">
                              <div class="img-box">
                                <img src={client} alt="" />
                              </div>
                              <div class="detail-box">
                                <div class="name">
                                  <h6>
                                    Alan Emerson
                                  </h6>
                                </div>
                                <p>
                                  Enim consequatur odio assumenda voluptas voluptatibus esse nobis officia. Magnam, aspernatur nostrum explicabo, distinctio laudantium delectus deserunt quia quidem magni corporis earum inventore totam consectetur corrupti! Corrupti, nihil sunt? Natus.
                                </p>
                                <i class="fa fa-quote-left" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="carousel_btn-container">
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample2Controls" data-bs-slide="prev">
                        <i class="bi bi-arrow-left" aria-hidden="true"></i>
                        <span class="sr-only">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselExample2Controls" data-bs-slide="next">
                        <i class="bi bi-arrow-right" aria-hidden="true"></i>
                        <span class="sr-only">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

         
        </section>

      </div> */}



      {/* client end */}

      {/* footer start */}
      {/* <div>
        <footer class="footer_section">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-3 footer_col">
                <div class="footer_contact">
                  <h4>
                    Reach at..
                  </h4>
                  <div class="contact_link_box">
                    <a href="">
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                      <span>
                        Location
                      </span>
                    </a>
                    <a href="">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      <span>
                        Call +91 73390 78908
                      </span>
                    </a>
                    <a href="">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                      <span>
                        shayaannai@123gmail.com
                      </span>
                    </a>
                  </div>
                </div>
                <div class="footer_social">
                  <a href="">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
              <div class="col-md-6 col-lg-3 footer_col">
                <div class="footer_detail">
                  <h4>
                    About
                  </h4>
                  <p>
                    People become victims to the ailments such as dengue, diabetic, hypertension, cardiac problem, typhoid, asthma etc very easily as their health is weak due to malnourishment.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-2 mx-auto footer_col">
                <div class="footer_link_box">
                  <h4>
                    Links
                  </h4>
                  <div class="footer_links">
                    <a class="active" href="index.html">
                      Home
                    </a>
                    <a class="" href="about.html">
                      About
                    </a>
                    <a class="" href="departments.html">
                      Departments
                    </a>
                    <a class="" href="doctors.html">
                      Doctors
                    </a>
                    <a class="" href="contact.html">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3 footer_col ">
                <h4>
                  Newsletter
                </h4>
                <form action="#">
                  <input type="email" placeholder="Enter email" />
                  <button type="submit">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
            <div class="footer-info">
              <p>
                &copy; <span id="displayYear"></span> All Rights Reserved By
                <a href="https://html.design/"> Sahaya Annai Hospital
                </a>
                <br />
                <br />
                &copy; <span id="displayYear"></span> Developed  By
                <a href="https://virtualtechnology.in/"> virtualtechnology</a>
              </p>

            </div>
          </div>
        </footer>
      </div> */}
      {/* <Footer /> */}
      {/* footer end */}

      {/* arrow start */}
      <div className="read-more" >
        <a href="#" className="downarrow-logo"
          onClick={scrollToTop}
          id="myBtn"
          title="Go to top"
          style={{ display: isVisible ? 'block' : 'none' }}>
          <i className="bi bi-arrow-up-circle"></i></a>
      </div>
      {/* arrow end */}






    </div>



  );
};
export default Home;