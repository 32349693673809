// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import logo from '../assets/images/medical-logo.png';
import { MdAddLocationAlt } from "react-icons/md";
import { FaBusinessTime } from "react-icons/fa";
import { TbDeviceMobile } from "react-icons/tb";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { useState } from 'react';

function Header() {
  // const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState('home');

  const handleSelect = (key) => {
    setActiveKey(key);
  };
  const phoneNumber = '06382617206'; // Replace this with the actual phone number
  const message = 'Hello, Sahaya Annai Hospital, how can i help you'; // Replace with your desired message 

  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)} `;



  return (
    <div >

      <div id="topbar" className="d-flex align-items-center py-2" >

        <div className="container-fluid d-flex gap-4">
          <div className="align-items-center d-none d-lg-flex ">
            <a href="/Contact"><i><MdAddLocationAlt /> </i><span className="num">Alanganallur Main Road, Koodal Nagar, Madurai.</span>
            </a>

          </div>
          <div className="align-items-center d-none d-xl-flex ">
            <a href="tel:+91 4526912324"><i><FaBusinessTime /></i><span className="num"> 24 * 7</span>
            </a>

          </div>
          <div className="align-items-center d-none d-md-flex  " >
            <a style={{ color: 'red' }} href="tel:+91 4526912324"><i ><TbDeviceMobile /> </i><span className="num">Emergency : +91 4526912324</span>
            </a>

          </div>
          <div className="d-flex align-items-center d-none d-sm-flex">
            <a style={{ color: '#068a65' }} href={whatsappUrl} target='blank'><i className="bi bi-whatsapp"> </i><span className="num">Appointment : +91- 6382617206</span>
            </a>
          </div>
          <div className=" align-items-center gap-2 d-none d-xl-flex">
            <a href="">
              <FaFacebook />
            </a>
            <a href="">
              <FaTwitter />
            </a>
            <a href="">
              <FaInstagramSquare />
            </a>
            <a href="">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg ">

        <div className="container ">
          <a className="navbar-brand" href="/">
            <img src={logo} className="logo-img" />
            {/* <span><h6>Sahaya Annai</h6></span> */}
          </a>
          <button className="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>

          {/* <div id="topbar" className="d-flex align-items-center py-2" >
              <div className="container d-flex align-items-center  justify-content-between gap-5">
                <div className="align-items-center d-none d-sm-flex ">
                  <a  href="tel:636931380"><i className="bi bi-telephone-fill"> </i><span className="num">Emergency Contact Number: +91 1234567890</span>
                  </a>

                </div>
                <div className="d-flex align-items-center d-none d-sm-flex">
                <a  href="tel:636931380"><i className="bi bi-telephone-fill"> </i><span className="num">Appointment Contact Number: +91 1234567890</span>
                  </a>
                </div>
              </div>
            </div> */}
          <div className="collapse navbar-collapse " id="navbarNav" variant="pills" activeKey={activeKey} onSelect={handleSelect}>

            <ul className="navbar-nav ms-auto" >
              <li className="nav-item">
                <Link className="nav-link " aria-current="page" to="/" eventKey="home">HOME</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="/About" eventKey="about">ABOUT</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="/Specialities" eventKey="specialities">SPECIALITIES</Link>
              </li>
              {/* <li className="nav-item">
                  <Link className="nav-link" to="/" eventKey="department">DEPARTMENT</Link>
                </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/Ourdoctor" eventKey="ourdoctor">DOCTORS</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/" eventKey="ourdoctor">HEALTH PACKAGES</Link>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                ACADAMY
                </Link>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to="/Acadamy">Sahaya Annai Trianing Institute</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Contact" eventKey="contact">CONTACT</Link>
              </li>
              {/* <li>
              <Link  className='btn-book text-center ' to="/" >Book  Appointment</Link>
              </li> */}
            </ul>

          </div>
        </div>

      </nav>
      <div className='border-design'></div>

    </div>
  );
}

export default Header;