import '../css/main.css';
import { FaCalendarAlt } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6"; 
import { FaSuitcaseMedical } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';

function Social() {

    const phoneNumber = '06382617206'; // Replace this with the actual phone number
  const message = 'Hello, Sahaya Annai Hospital, how can i help you'; // Replace with your desired message

  // Construct the WhatsApp link
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)} `;
    return (
        <div className="socialbar text-center">
            <div  className='social-border'>
            <Link to='/Contact'>
           <i><FaCalendarAlt /></i>
            <p>Book 
                Appointment</p> 
           </Link>
            </div>
          
            <div className='social-border'>
            <a href={whatsappUrl} target='blank'><i>
            <FaUserDoctor />  
            </i>
            <p className=''>Second  Opinion</p>
                </a>
            </div>   
            
            <div className='social-border '>
                <Link to='/'>
                <i>
            <FaSuitcaseMedical />
            </i>
            <p className=''>Health Package</p></Link>
            
            </div>
            <div >
                <a href={whatsappUrl} target='blank'>
                <i>
            {/* <PiWhatsappLogoDuotone /> */}
            <img src='https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png'  style={{color:'#25D366' , width:'40px', height:'40px'}}/>
            </i>
            <p className=''>Whatsapp  Chat</p></a>
           
            </div>
           
        </div>
    );
}
export default Social;