
// import * as yup from'yup';

// export const Formvalidation = yup.object({
//     email:yup.string().email("please enter valid email").required("please enter email"),
//     password:yup.string().min(5).required("please enter password")
// });

import * as yup from 'yup';

export const basicSchema = yup.object().shape({
    name: yup
        .string()
        .test('name', 'Please enter a valid name', value => {
            // Add your custom validation logic for the name field
            return /^[a-zA-Z\s]+$/.test(value);
        })
        .min(3, 'Minimum 3 characters required')
        .required('This field is required'),

    // email: yup
    //     .string()
    //     .email('Please enter a valid email address')
    //     .required('This field is required'),

    phonenumber: yup
        .string()
        .test('phonenumber', 'Please enter a valid phone number', value => {
            // Add your custom validation logic for the phone number field
            return /^[0-9]+$/.test(value);
        })
        .min(10, 'Minimum 10 characters required')
        .required('This field is required'),

    servicename: yup
        .string()
        .test('name', 'Please enter a valid name', value => {
            // Add your custom validation logic for the name field
            return /^[a-zA-Z\s]+$/.test(value);
        })
        .min(3, 'Minimum 3 characters required')
        .required('This field is required'),
    // password: yup
    //     .string()
    //     .min(5, 'Minimum 5 characters required')
    //     .required('This field is required'),

    // message: yup
    //     .string()
    //     .min(5, 'Minimum 5 characters required')
    //     .required('This field is required'),
});