
// import "./demo.css";

import bg from "./assets/images/client.jpg";
// import reception from "./assets/images/reception.png"
import underline from "./assets/images/underline.png";

import reception from './assets/images/reception1.png';
import pharmacy from './assets/images/pharmacy1.avif';
import emargency from './assets/images/emargency.jpeg';
import icu from './assets/images/icu2.jpg';
import genral from './assets/images/genral.jpeg';
import prvt from './assets/images/prvt.jpeg';
import operation from './assets/images/operation.jpeg';
import lab from './assets/images/lab.jpeg';
import dental from './assets/images/dental.jpeg';
import sicu from './assets/images/sicu.png';
import { useEffect } from 'react';

function Department() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    < >
      <div class="background-department  text-lg-start">
        <div class="container absolut">
          <p >We are here for your care. </p>
          <h1 className="pt-3"> Departments</h1>
        </div>
      </div>
      <div class="department_container">
        <div class="container  py-5" >
        <div class="pt-5 container">
            <div className="nurseAbout-icon pt-5 px-2">
            <h1 className="  pt-5   depart-h1  ">
               Department Details
            </h1>
          </div> 
              <img src={underline} style={{ width: "200px",  }}></img>
              
            </div>
          <div class="container my-5">
            <div class="row ">

              <div class="col-12 col-lg-12 my-3">

                <div class="row dep">
                  <div class="col-12 col-lg-3 col-md-4 element" >
                    <div class="card m-2 border-0" >
                      <div class="crd-img-div">
                        <img src={reception} class="card-img-top" alt="reception" />
                      </div>
                      <div class="card-body card-border-0">
                        <h5 class="card-title">Reception</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-4 element" >
                    <div class="card m-2 border-0" >
                      <div class="crd-img-div">
                        <img src={pharmacy} class="card-img-top" alt="reception" />
                      </div>
                      <div class="card-body card-border-0">
                        <h5 class="card-title">Pharmacy</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-4 element" >
                    <div class="card m-2 border-0" >
                      <div class="crd-img-div">
                        <img src={emargency} class="card-img-top" alt="reception" />
                      </div>
                      <div class="card-body card-border-0">
                        <h5 class="card-title">Emergancy Ward</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-4 element" >
                    <div class="card m-2 border-0" >
                      <div class="crd-img-div">
                        <img src={icu} class="card-img-top" alt="reception" />
                      </div>
                      <div class="card-body card-border-0">
                        <h5 class="card-title">ICU</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-4 element" >
                    <div class="card m-2 border-0" >
                      <div class="crd-img-div">
                        <img src={genral} class="card-img-top" alt="reception" />
                      </div>
                      <div class="card-body card-border-0">
                        <h5 class="card-title">General Ward</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-4 element" >
                    <div class="card m-2 border-0" >
                      <div class="crd-img-div">
                        <img src={prvt} class="card-img-top" alt="reception" />
                      </div>
                      <div class="card-body card-border-0">
                        <h5 class="card-title">Private Ward</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-4 element" >
                    <div class="card m-2 border-0" >
                      <div class="crd-img-div">
                        <img src={operation} class="card-img-top" alt="reception" />
                      </div>
                      <div class="card-body card-border-0">
                        <h5 class="card-title">Operation Theatre</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-4 element" >
                    <div class="card m-2 border-0" >
                      <div class="crd-img-div">
                        <img src={lab} class="card-img-top" alt="reception" />
                      </div>
                      <div class="card-body card-border-0">
                        <h5 class="card-title">Laboratory</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-4 element" >
                    <div class="card m-2 border-0" >
                      <div class="crd-img-div">
                        <img src={dental} class="card-img-top" alt="reception" />
                      </div>
                      <div class="card-body card-border-0">
                        <h5 class="card-title">Dental</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 col-md-4 element" >
                    <div class="card m-2 border-0" >
                      <div class="crd-img-div">
                        <img src={sicu} class="card-img-top" alt="reception" />
                      </div>
                      <div class="card-body card-border-0">
                        <h5 class="card-title">SICU</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>

        </div>

      </div>
    </>
  );
};

export default Department;